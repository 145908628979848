import React, { useState } from "react";
import LogoSvg from '../static/img/LogoIdeiperu.svg';
import {LabelStatus, InputForm, ButtonForm} from '../components/ui'
// import { useAuth } from '../context/authContext'
// import { useNavigate } from 'react-router-dom'

const Register = () => {
    const [user, setUser] = useState({
        Email: '',
        Password: '',
        Password2:'',
    })
    const [error, setError] = useState('Registrar un Correo')
    const [stateText, setStateText] = useState('normal')

    // const {signup} = useAuth()
    // const navigate = useNavigate()

    const handleChange = ({target: {name, value}}) => {
        setUser({...user, [name]: value})
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        if(user.Password !== user.Password2){
            setError('La contraseñas son diferentes')
            setStateText('error')
            return
        }
        try{
            setError('Solicite permisos al administrador para registrarse')
            setStateText('error')
            return
            // await signup(user.Email,user.Password)
            // setStateText('success')
            // navigate('/')
        }catch (error){
            if (error.code === 'auth/weak-password') {
                setError('La contraseña debe tener mas de 6 caracteres')
                setStateText('error')
            }else if(error.code === 'auth/email-already-in-use'){
                setError('El correo ya se encuentra registrado')
                setStateText('error')

            }else{
                setError(error.code)
                setStateText('error')
            }
        }
    }

    return (
        <form className="bg-white flex flex-col text-center px-[3.6458%] py-[2.8%] rounded-2xl w-[90%] h-auto absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%] gap-8 sm:w-[80%] sm:px-8 sm:py-10 md:w-[60%] lg:w-[33%] xl:px-[3.6458%] 2xl:w-[27.34375%]" onSubmit={handleSubmit}>
            <img className="w-[82.78%] m-auto" src={LogoSvg} alt="LogoIdeiperu"/>
            <LabelStatus state={stateText} >{error && <p>{error}</p>}</LabelStatus>
            <div className="flex flex-col text-center w-full  gap-[15px] ">
                <InputForm 
                    id="Email"
                    name="Email"
                    type="email"
                    placeholder=""
                    onChange={handleChange}
                    >Ingrese su Correo</InputForm>
                <InputForm 
                    id="Password"
                    name="Password"
                    type="password"
                    placeholder=""
                    onChange={handleChange}
                    >Ingrese su Contraseña</InputForm>
                <InputForm 
                    id="Password2"
                    name="Password2"
                    type="password"
                    placeholder=""
                    onChange={handleChange}
                    >Repita su Contraseña</InputForm>
            </div>
            <div className="flex items-center justify-around">
                <ButtonForm tipo="Form" >REGISTRAR CORREO</ButtonForm>

            </div>
        </form>
    )
}

export default Register