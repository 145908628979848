import React, { useEffect } from 'react'
import Check from '../../static/svg/Check.svg'
import Error from '../../static/svg/Error.svg'
import Cross from '../../static/svg/Cross.svg'

export default function Alert({stateAlert, closeAlert}) {

    useEffect(() => {
        if (stateAlert !== '') {
            const timer = setTimeout(() => {
                closeAlert();
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [stateAlert]);
    return (
        <>
            <div className="rounded-xl border border-gray-100 bg-white p-4 z-20 absolute top-2 left-1/2 transform -translate-x-1/2 max-[600px]:w-[80%] max-[400px]:w-[95%]">
                <div className="flex items-start gap-4">
                    <span className="text-green-600">
                        <img className='h-12 ' src={stateAlert === 'Success' ? Check:Error}  alt="check" />
                    </span>

                    <div className="flex-1">
                        <strong className="block font-medium text-gray-900">{stateAlert === 'Success' ? 'Cambios guardados !':'Cambios no guardados'} </strong>
                        <p className="mt-1 text-sm text-gray-700">{stateAlert === 'Success' ? 'Todos los cambios han sido efectuados exitosamente.':'No se ah podida guardar la información correctamente.'}</p>
                    </div>

                    <button className="text-gray-500 transition hover:text-gray-600" onClick={closeAlert}>
                        <img className='h-6 ' src={Cross}  alt="check" />
                    </button>
                </div>
            </div>
        </>
    )
}
