export default function ButtonKeyFrame({ children, ...props }) {
    return (
        <button 
            className="w-[50%] h-full py-4 relative inline-block text-[#B80D01] text-[20px] hover:bg-[#B80D01] hover:text-white hover:rounded-2 hover:shadow-[0px_0px_12px_0px_#B80D01] duration-500 no-underline uppercase overflow-hidden border-none bg-none tracking-[8px]" 
            {...props} 
            >
                <span className='absolute block top-0 left-[-100%] w-full h-[2px] rotate-[0deg] bg-gradient-to-r from-transparent to-[#B80D01] animate-span1'></span>
                <span className='absolute block top-[-100%] right-0 w-[2px] h-full rotate-[180deg] bg-gradient-to-r from-transparent to-[#B80D01] animate-span2 ' style={{animationDelay:'0.25s'}}></span>
                <span className='absolute block bottom-0 right-[-100%] w-full h-[2px] rotate-[180deg] bg-gradient-to-r from-transparent to-[#B80D01] animate-span3 ' style={{animationDelay:'0.5s'}}></span>
                <span className='absolute block left-0 bottom-[-100%] w-[2px] h-full rotate-[0deg] bg-gradient-to-r from-transparent to-[#B80D01] animate-span4 'style={{animationDelay:'0.75s'}}></span>
            {children}
        </button>
    );
  }
  