export default function CheckBox({ name, children, id, checked, onChange, ...props }) {
  return (
    <>
      <fieldset>
        <label htmlFor={id} className="flex cursor-pointer items-center gap-2 px-[5px]">
          <div className="flex items-center">
            ​
            <input
              type="checkbox"
              className="size-4 rounded border-gray-300"
              id={id}
              name={name}
              checked={checked}
              onChange={onChange}
            />
          </div>
          <div>
            <strong className="font-medium text-gray-900" {...props}>
              {children}
            </strong>
          </div>
        </label>
      </fieldset>
    </>
  );
}
