export default function BackgroundStyle({ children }) {
  return (
    <>
        <div className="w-full h-screen bg-[#CACACA]">
            <div className="w-full h-[82.51%] bg-[#B80D01]" style={{ clipPath: 'polygon(0 0, 100% 0, 100% 34.60%, 0 100%)' }}></div>
            {children}
        </div>
    </>
  )
}
