import { formatRealtimeData } from "./formatRealtimeData"

export const updateData = (dataToUpload) => {
    try {
        const updates = {}
        const dataToDB = formatRealtimeData(dataToUpload)
        const camposArray = Object.values(dataToDB)
        let i = 0
        updates['PV_Southern/Blasting_Panel/Address/0/Alarms/LastChangeListened'] = false
        updates['PV_Southern/Blasting_Panel/Address/0/Alarms/LastChangeMade'] = false
        for (const campo of camposArray) {
            if(i == 4){
                updates[`PV_Southern/Blasting_Panel/Address/0/Fields/${5}/Text`] = campo
            }else if(i == 5){
                updates[`PV_Southern/Blasting_Panel/Address/0/Fields/${4}/Text`] = campo
            }else if(i == 11){
                if (campo.length > 17) {
                    updates[`PV_Southern/Blasting_Panel/Address/0/Fields/${11}/Text`] = '    ' + campo;
                } else {
                    updates[`PV_Southern/Blasting_Panel/Address/0/Fields/${11}/Text`] = campo;
                }
            }else{
                updates[`PV_Southern/Blasting_Panel/Address/0/Fields/${i}/Text`] = campo
            }
            i = i + 1
        }
        const updatesArray = Object.entries(updates).map(([key, value]) => ({ key, value }));
        return updatesArray
    } catch (error) {
        alert('Error al subir datos: ' + error.message);
    }
}
