export  default function InputForm({children, id, ...props}) {
  return (
    <>
    <label
      htmlFor={id}
      className="relative font-Poppins w-full block overflow-hidden rounded-md border border-gray-200 px-3 pt-5 shadow-sm focus-within:border-blue-600 focus-within:ring-1 focus-within:ring-blue-600  "
    >
      <input
        id={id}
        {...props}
        className="peer font-Poppins text-base h-8 w-full border-none bg-transparent p-0 placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0 "
      />

      <span
        className="absolute font-Poppins start-3 top-4 -translate-y-1/2 text-xs text-[#969696] transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-sm peer-focus:top-4 peer-focus:text-xs"
      >
        {children}
      </span>
    </label>
    </>
  )
}