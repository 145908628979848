import React, { useEffect, useRef, useState } from 'react';
import P10text from './P10text';
import LogoSouthernSvg from '../../static/img/LogoSouthern.svg';
import './borrar.css'


export default function PanelGrafico({ className, datos }) {
  const parentDivRef = useRef(null);
  const [fontSize, setFontSize] = useState(null);
  const [fontSizeTitle, setFontSizeTitle] = useState(null);
  const [classAnimate, setClassAnimate] = useState('');
  const combinedClassName = className ? `aspect-[42/26] p-1 bg-white  ${className}` : `aspect-[42/26] p-1 bg-white `;

  useEffect(() => {
      const handleResize = () => {
          const parentDivHeight = parentDivRef.current.clientHeight;
          const fontSizePercentage = parentDivHeight * 0.05; 
          const calculatedFontSize = `${fontSizePercentage}px`; 
          const calculatedFontSizeTitle = `${fontSizePercentage*1.75}px`; 
          setFontSize(calculatedFontSize); 
          setFontSizeTitle(calculatedFontSizeTitle); 
      };

      handleResize(); 
      window.addEventListener('resize', handleResize); 
      return () => window.removeEventListener('resize', handleResize); 
  }, []);

    const containerRef = useRef(null);
    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            const scrollWidth = container.scrollWidth;
            const clientWidth = container.clientWidth;

            if (scrollWidth > clientWidth) {
                setClassAnimate('animate-aniMarquee  !flex !justify-start')
            }else{
                const textLength = container.querySelector('span').textContent.length;
                if(textLength >= 21 && scrollWidth === clientWidth){
                    setClassAnimate('animate-aniMarquee  !flex !justify-start')
                }else{
                    setClassAnimate('flex justify-center')
                }
            }
        }
    }, [datos,]);
  
  return (
    <div className={combinedClassName} ref={parentDivRef}>
      <div className="w-full h-full border-8 border-[#B80D01] relative flex justify-center items-center p-5">
        {/* ---------------------------  ANUNCIO Y TITULO  --------------------------- */}
        <img className="w-[23.7%] absolute top-2 left-2" src={LogoSouthernSvg}  alt="LogoSouthern" />
        <span 
            className="w-[28.82%] aspect-[6/1] flex items-center justify-end font-Poppins font-bold  absolute top-[1.5%] right-[0.7%] whitespace-nowrap overflow-clip" 
            style={{ fontSize: fontSize}}
        >MINA TOQUEPALA</span>
        <span 
            className="w-[68.44%] aspect-[9/1] border-2 border-black bg-[#282828] flex items-center justify-center text-[#B80D01] font-Poppins font-bold text-3xl absolute top-[10%] right-[0.7%] whitespace-nowrap overflow-hidden" 
            style={{ fontSize: fontSizeTitle, WebkitTextStrokeWidth: '2px', WebkitTextStrokeColor: 'black' }}
        >
            <div className={`w-full whitespace-nowrap ${classAnimate}`} ref={containerRef}>
                <span className="inline-block ml-3">{datos.titulo}</span>
            </div>
        </span>

        {/* ---------------------------  HORA  --------------------------- */}
        <P10text
            p10="1x3"
            fontSize={fontSize}
            right={0.7}
            top={28.06}
            name="HORA:"
        >
            <p>{datos.desde}-{datos.hasta}</p>
        </P10text>
        
        {/* ---------------------------  FECHA  --------------------------- */}
        <P10text
            p10="1x3"
            fontSize={fontSize}
            right={42.53}
            top={28.06}
            name="FECHA:"
        >
            <p>{datos.fecha}</p>
        </P10text>
                                            
        {/* ---------------------------  DISPAROS  --------------------------- */}
        
        <span 
            className="w-[22.82%] aspect-[6/1] flex items-center justify-center font-Poppins font-bold absolute top-[41.5%] right-[0.7%] whitespace-nowrap overflow-hidden" 
            style={{ fontSize: fontSize }}
        >DISPARO 3</span>
        <span 
            className="w-[22.82%] aspect-[6/1] flex items-center justify-center font-Poppins font-bold absolute top-[41.5%] right-[25.89%] whitespace-nowrap overflow-hidden" 
            style={{ fontSize: fontSize }}
        >DISPARO 2</span>
        <span 
            className="w-[22.82%] aspect-[6/1] flex items-center justify-center font-Poppins font-bold absolute top-[41.5%] right-[51.09%] whitespace-nowrap overflow-hidden" 
            style={{ fontSize: fontSize }}
        >DISPARO 1</span>
                    
        {/* ---------------------------  PALA  --------------------------- */}
        <P10text 
            p10="1x3"
            fontSize={fontSize}
            right="0.7"
            top="49.5"
            name="PALA:"
        >
            <span>{datos.disparo1Pala}</span>
            <span>{datos.disparo2Pala}</span>
            <span>{datos.disparo3Pala}</span>
        </P10text>
                    
        {/* ---------------------------  PROYECTO  --------------------------- */}
        
        <P10text 
            p10="3x3"
            fontSize={fontSize}
            right="0.7"
            top="58.5"
            name="PROYECTO:"
        >
            <p>{datos.disparo1Proyecto1}</p>
            <p>{datos.disparo1Proyecto2}</p>
            <p>{datos.disparo1Proyecto3}</p>
            <p>{datos.disparo2Proyecto1}</p>
            <p>{datos.disparo2Proyecto2}</p>
            <p>{datos.disparo2Proyecto3}</p>
            <p>{datos.disparo3Proyecto1}</p>
            <p>{datos.disparo3Proyecto2}</p>
            <p>{datos.disparo3Proyecto3}</p>
        </P10text>
                    
        {/* ---------------------------  TIPO  --------------------------- */}
        
        
        <P10text 
            p10="3x3"
            fontSize={fontSize}
            right="0.7"
            top="80"
            name="TIPO:"
        >
            <p>{datos.disparo1Tipo1 ? 'PRIMARIO': ''}</p>
            <p>{datos.disparo1Tipo2 ? 'PRECORTE': ''}</p>
            <p>{datos.disparo1Tipo3 ? 'SECUNDARIO': ''}</p>
            <p>{datos.disparo2Tipo1 ? 'PRIMARIO': ''}</p>
            <p>{datos.disparo2Tipo2 ? 'PRECORTE': ''}</p>
            <p>{datos.disparo2Tipo3 ? 'SECUNDARIO': ''}</p>
            <p>{datos.disparo3Tipo1 ? 'PRIMARIO': ''}</p>
            <p>{datos.disparo3Tipo2 ? 'PRECORTE': ''}</p>
            <p>{datos.disparo3Tipo3 ? 'SECUNDARIO': ''}</p>
        </P10text>
      </div>
    </div>
  )
}
