import { useEffect, useState } from "react";

export  default function InputFormPanel({children, id, className, secondClass, value, required, ...props}) {
  const combinedClassName = className ? `flex flex-col ${className}` : `flex flex-col`;
  const combinedSecondClassName = secondClass ? `block mb-0 ms-1 text-md font-bold text-gray-500 font-Formulario ${secondClass}` : `block mb-0 ms-1 text-md font-bold text-gray-500 font-Formulario`;
  const [classInput, setClassInput] = useState('')
  const [placeholderText, setPlaceholderText] = useState('')

  
  useEffect(() => {
    if (placeholderText === 'Este campo es requerido') {
      setClassInput('')
      setPlaceholderText('')
    }
  }, [required]);

  const handleBlur = ({target: {name, value}}) => {
    if ([required] && value.trim() === '') {
      setClassInput('bg-red-200')
      setPlaceholderText('Este campo es requerido')
    }else{
      setClassInput('')
      setPlaceholderText('')
    }
  }

  return (
    <>
      <div className={combinedClassName} >
          <label 
              htmlFor={id}
              className={combinedSecondClassName}
          >{children}</label>

          <input 
              id={id}
              value={value}
              onBlur={handleBlur}
              required={required}
              placeholder={placeholderText}
              className={`shadow-md text-[2rem] border font-Poppins bg-gray-100 text-black font-medium text-sm rounded-lg block w-full p-2.5  focus:border-[#B80D01] focus:outline-none focus:bg-white disabled:bg-gray-300 disabled:text-gray-300 ${classInput}`} 
              {...props}
          />
      </div>

    </>
  )
}