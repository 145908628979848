import React from 'react'
import LogoWeb from '../static/img/LogoIdeiperu.svg';
import Logout from '../static/img/LogOut.svg';
import { Link } from 'react-router-dom';

export default function Header({salir}) {
  return (
    <header className="w-full fixed top-0 shadow-[0px_0px_18px_1px_#000] z-10">
        <div className="w-full h-16 px-2 bg-[#B80D01] flex justify-center items-center relative max-[900px]:h-14">
            <a className="absolute left-2" href="/home">
                <img className='h-14 max-[900px]:h-10 max-[700px]:h-8 max-[450px]:h-7' src={LogoWeb}  alt="LogoSouthern" />
            </a>
            <div className='font-Poppins font-semibold text-[1.8rem] text-white' style={{textShadow:'#fc0 1px 1px 6px'}}>
                <Link to={'/home'}>Home</Link>
            </div>
            <button 
                className="absolute flex justify-center items-center w-fit right-2 px-8 py-1 rounded-lg bg-white text-[#B80D01] font-Poppins font-medium text-[1rem] drop-shadow-lg shadow-[2px_2px_11px_-3px_#a00_inset] hover:text-[1.1rem] transition-all ease-in-out delay-100 focus:text-[1.1rem] max-[450px]:text-[.8rem]"
                onClick={salir}
            >
                <img className='h-auto' src={Logout}  alt="LogoSouthern" />
                Salir
            </button>
        </div>
    </header>
  )
}
