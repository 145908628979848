
import { useAuth } from "../context/authContext";
import { Navigate } from 'react-router-dom'
import BackgroundStyle from "../components/ui/BackgroundStylePage";

export function ProtectedRoute({ children }) {
    const {user, loading} = useAuth()
    if(loading){
        return (<>  
            <BackgroundStyle></BackgroundStyle>
        </>)
    }
    
    if(!user) return <Navigate to='/' />

    return (
        <div>{children}</div>
    )
}
