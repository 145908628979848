export default function LabelStatus({ children, className, state, ...props }) {
    let combinedClassName = className ? `font-Poppins text-[#6B6B6B] text-lg font-bold leading-5 ${className}` : `font-Poppins text-[#6B6B6B] text-lg font-bold leading-5`;

    if( state === 'error' ){
      combinedClassName = combinedClassName + ' text-[#B80D01]'
    }else if( state === 'success' ){
      combinedClassName = combinedClassName + ' text-green'
    }

    return (
      <>
        <h5 className={combinedClassName} {...props}>{children}</h5>
      </>
    );
  }