export const formatRealtimeData = (dataWeb) => {
    try{
        const trimmedData = Object.fromEntries(
            Object.entries(dataWeb).map(([key, value]) => [key, typeof value === 'string' ? value=== ' ' ? value:value.trim() : value])
        );
        const camposCompletos = {
            campo0: `~${trimmedData.disparo1Tipo1 ? 'PRIMARIO': ' '}~${trimmedData.disparo1Tipo2 ? 'PRECORTE': ' '}~${trimmedData.disparo1Tipo3 ? 'SECUNDARIO': ' '}~`,
            campo1: `~${trimmedData.disparo1Proyecto1 || ' '}~${trimmedData.disparo1Proyecto2 || ' '}~${trimmedData.disparo1Proyecto3 || ' '}~`,
            campo2: `${trimmedData.disparo1Pala}`,
            campo3: `~${trimmedData.disparo2Tipo1 ? 'PRIMARIO': ' '}~${trimmedData.disparo2Tipo2 ? 'PRECORTE': ' '}~${trimmedData.disparo2Tipo3 ? 'SECUNDARIO': ' '}~`,
            campo4: `~${trimmedData.disparo2Proyecto1 || ' '}~${trimmedData.disparo2Proyecto2 || ' '}~${trimmedData.disparo2Proyecto3 || ' '}~`,
            campo5: `${trimmedData.disparo2Pala}`,
            campo6: `~${trimmedData.disparo3Tipo1 ? 'PRIMARIO': ' '}~${trimmedData.disparo3Tipo2 ? 'PRECORTE': ' '}~${trimmedData.disparo3Tipo3 ? 'SECUNDARIO': ' '}~`,
            campo7: `~${trimmedData.disparo3Proyecto1 || ' '}~${trimmedData.disparo3Proyecto2 || ' '}~${trimmedData.disparo3Proyecto3 || ' '}~`,
            campo8: `${trimmedData.disparo3Pala}`,
            campo9: `${trimmedData.desde}-${trimmedData.hasta}`,
            campo10: `${trimmedData.fecha}`,
            campo11: `${trimmedData.titulo}`,
        }
        return camposCompletos
    } catch (error) {
        console.error('Error en formatData:', error);
        return {};
    }
}
