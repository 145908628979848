import React, { useEffect, useRef, useState } from 'react'
import InputFormPanel from './ui/InputFormPanel';
import Carousel from './ui/Carousel';
import ButtonGroup from './ui/ButtonGroup';
import { ButtonKeyFrame } from './ui';
import CheckBoxInput from './ui/CheckBoxInput';


export default function FormsPanel({className, datos, onModifyDatos}) {
    const [indexButton, setIndexButton] = useState(0)
    const [data, setData] = useState(datos)
    const refsArray = [
        useRef(null),
        useRef(null),
        useRef(null)
    ];
    useEffect(() => {
        setData(datos);
    }, [datos]);
    const combinedClassName = className ? `h-[90%] flex flex-col justify-center items-center bg-white rounded-3xl shadow-[0px_4px_10px_-2px_rgba(184,13,1)] max-[900px]:h-auto  ${className}` : `h-[90%] flex flex-col justify-center items-center bg-white rounded-3xl shadow-[0px_4px_10px_-2px_rgba(184,13,1)] max-[900px]:h-auto`;

    const handleChange = ({target: {name, value}}) => {
        if( name === 'fecha' ) value = value.split('-').reverse().join('-')
        setData({...data, [name]: value})
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        onModifyDatos(data)
    }

    const handleInvalid = (e) => {
        e.preventDefault(); 
        if (
            data['disparo1Pala'] === '' ||
            (data['disparo1Proyecto1'] === '' && data['disparo1Tipo1']) ||
            (data['disparo1Proyecto2'] === '' && data['disparo1Tipo2']) ||
            (data['disparo1Proyecto3'] === '' && data['disparo1Tipo3'])
        ) {
            refsArray[0].current.click();
        }else if (
            data['disparo2Pala'] === '' ||
            (data['disparo2Proyecto1'] === '' && data['disparo2Tipo1']) ||
            (data['disparo2Proyecto2'] === '' && data['disparo2Tipo2']) ||
            (data['disparo2Proyecto3'] === '' && data['disparo2Tipo3'])
        ) {
            refsArray[1].current.click();
        }else if (
            data['disparo3Pala'] === '' ||
            (data['disparo3Proyecto1'] === '' && data['disparo3Tipo1']) ||
            (data['disparo3Proyecto2'] === '' && data['disparo3Tipo2']) ||
            (data['disparo3Proyecto3'] === '' && data['disparo3Tipo3'])
        ) {
            refsArray[2].current.click();
        }
    };

    const handleButtonClick = (index) => {
        setIndexButton(index);
    };

    const onModifyData = (stateCheck) => {
        setData({...data, ...stateCheck})
    }


    return (
        <div className={combinedClassName}>
            <span  className='w-full mt-5 text-center flex flex-col font-Robotoo font-medium text-white' >
                <span className='text-[3.2rem] max-[1200px]:text-[2.8rem] max-[1050px]:text-[2.4rem]' style={{ WebkitTextStrokeWidth: '2px', WebkitTextStrokeColor: '#B80D01' }}>CONFIGURACIÓN</span> 
                <span className='mt-[-10px] text-[2rem] text-[#B80D01] max-[1200px]:text-[1.6rem] max-[1050px]:text-[1.2rem]'>Panel de Voladura</span> 
            </span>
            <span className='w-[90%] h-full flex flex-col gap-4 justify-start items-center overflow-y-auto overflow-x-hidden'>
                <form className="w-full flex gap-5 pt-5 pb-5 flex-wrap" onSubmit={handleSubmit} onInvalid={handleInvalid}>

                    <InputFormPanel
                        className="w-full"
                        name="titulo"
                        type="text"
                        value={data.titulo} 
                        onChange={handleChange}
                        required
                    >ENCABEZADO</InputFormPanel>

                    <InputFormPanel
                        className="flex-1"
                        value={data.fecha.split('-').reverse().join('-')} 
                        name="fecha"
                        onChange={handleChange}
                        type="date"
                        required
                    >FECHA:</InputFormPanel>

                    <div className='flex gap-5 flex-wrap w-full min-[1402px]:flex-1'>
                        <InputFormPanel
                            className="flex-1"
                            value={data.desde} 
                            name="desde"
                            onChange={handleChange}
                            type="time"
                            required
                        >DESDE:</InputFormPanel>

                        <InputFormPanel
                            className="flex-1"
                            value={data.hasta} 
                            name="hasta"
                            onChange={handleChange}
                            type="time"
                            required
                        >HASTA:</InputFormPanel>
                                            
                    </div>

                    <ButtonGroup
                        handleButtonClick={handleButtonClick}
                        indexActive={indexButton}
                    >
                        <span ref={refsArray[0]} > DISPARO 1 </span>
                        <span ref={refsArray[1]} > DISPARO 2 </span>
                        <span ref={refsArray[2]} > DISPARO 3 </span>
                    </ButtonGroup>
                    
                    <div className="bg-gradient-to-r from-[#ffffff] via-[#B80D01] to-[#ffffff]  w-full h-[3px]  rounded-lg"></div>
                    
                    <Carousel posicion={indexButton}>
                        <div className='flex flex-col'>
                            <InputFormPanel
                                className="w-full mb-5"
                                name="disparo1Pala"
                                value={data.disparo1Pala} 
                                onChange={handleChange}
                                required
                            >PALA</InputFormPanel>
                            <CheckBoxInput
                                label="PRIMARIO"
                                checked={data.disparo1Tipo1}
                                onChange={handleChange}
                                name="CheckBoxInput_1_1"
                                value={data.disparo1Proyecto1}
                                required
                                disabled={!data.disparo1Tipo1}
                                onModifyData={onModifyData}
                            >
                            </CheckBoxInput>
                            <CheckBoxInput
                                label="PRECORTE"
                                checked={data.disparo1Tipo2}
                                onChange={handleChange}
                                name="CheckBoxInput_1_2"
                                value={data.disparo1Proyecto2}
                                required
                                disabled={!data.disparo1Tipo2}
                                onModifyData={onModifyData}
                            >
                            </CheckBoxInput>
                            <CheckBoxInput
                                label="SECUNDARIO"
                                checked={data.disparo1Tipo3}
                                onChange={handleChange}
                                name="CheckBoxInput_1_3"
                                value={data.disparo1Proyecto3}
                                required
                                disabled={!data.disparo1Tipo3}
                                onModifyData={onModifyData}
                            >
                            </CheckBoxInput>
                        </div>
                        <div className='flex flex-col '>
                            <InputFormPanel
                                className="w-full mb-5"
                                name="disparo2Pala"
                                value={data.disparo2Pala} 
                                onChange={handleChange}
                                required
                            >PALA</InputFormPanel>
                            <CheckBoxInput
                                label="PRIMARIO"
                                checked={data.disparo2Tipo1}
                                onChange={handleChange}
                                name="CheckBoxInput_2_1"
                                value={data.disparo2Proyecto1}
                                required
                                disabled={!data.disparo2Tipo1}
                                onModifyData={onModifyData}
                            >
                            </CheckBoxInput>
                            <CheckBoxInput
                                label="PRECORTE"
                                checked={data.disparo2Tipo2}
                                onChange={handleChange}
                                name="CheckBoxInput_2_2"
                                value={data.disparo2Proyecto2}
                                required
                                disabled={!data.disparo2Tipo2}
                                onModifyData={onModifyData}
                            >
                            </CheckBoxInput>
                            <CheckBoxInput
                                label="SECUNDARIO"
                                checked={data.disparo2Tipo3}
                                onChange={handleChange}
                                name="CheckBoxInput_2_3"
                                value={data.disparo2Proyecto3}
                                required
                                disabled={!data.disparo2Tipo3}
                                onModifyData={onModifyData}
                            >
                            </CheckBoxInput>
                        </div>
                        <div className='flex flex-col '>
                            <InputFormPanel
                                className="w-full mb-5"
                                name="disparo3Pala"
                                value={data.disparo3Pala} 
                                onChange={handleChange}
                                required
                            >PALA</InputFormPanel>
                            <CheckBoxInput
                                label="PRIMARIO"
                                checked={data.disparo3Tipo1}
                                onChange={handleChange}
                                name="CheckBoxInput_3_1"
                                value={data.disparo3Proyecto1}
                                required
                                disabled={!data.disparo3Tipo1}
                                onModifyData={onModifyData}
                            >
                            </CheckBoxInput>
                            <CheckBoxInput
                                label="PRECORTE"
                                checked={data.disparo3Tipo2}
                                onChange={handleChange}
                                name="CheckBoxInput_3_2"
                                value={data.disparo3Proyecto2}
                                required
                                disabled={!data.disparo3Tipo2}
                                onModifyData={onModifyData}
                            >
                            </CheckBoxInput>
                            <CheckBoxInput
                                label="SECUNDARIO"
                                checked={data.disparo3Tipo3}
                                onChange={handleChange}
                                name="CheckBoxInput_3_3"
                                value={data.disparo3Proyecto3}
                                required
                                disabled={!data.disparo3Tipo3}
                                onModifyData={onModifyData}
                            >
                            </CheckBoxInput>
                        </div>
                    </Carousel>
                    
                    <div className="bg-gradient-to-r from-[#ffffff] via-[#B80D01] to-[#ffffff]  w-full h-[3px]  rounded-lg"></div>
                    <div className="w-full text-center ">
                        <ButtonKeyFrame
                            id="Enviar" 
                            type="submit" 
                            name="Enviar" 
                            value="Enviar"
                        >Enviar</ButtonKeyFrame>
                    </div>
                </form>

            </span>
        </div>
    )
}
