import React, { useEffect, useState } from 'react';
import { CheckBox, InputFormPanel } from '.';

export default function CheckBoxInput({ name, label, value, second, checked, onModifyData }) {
    // console.log(name, label, value, second, checked)
    const [isChecked, setIsChecked] = useState(checked);
    const [textInput, setTextInput] = useState(value);
    const nameCheck = `disparo${name.split('_')[1]}Tipo${name.split('_')[2]}`
    const nameInput = `disparo${name.split('_')[1]}Proyecto${name.split('_')[2]}`

    useEffect(() => {
        setIsChecked(checked);
    }, [checked]);

    useEffect(() => {
        setTextInput(value);
    }, [value]);

    
    const handleChange = ({ target: { name, checked, value } }) => {
        if (name.includes('Tipo')) {
            setIsChecked(checked);
            const newData = {
                [nameCheck]: !isChecked,
                [nameInput]: !isChecked ? textInput : '',
            }
            onModifyData(newData)
        } else {
            setTextInput(value);
            const newData = {
                [nameCheck]: isChecked,
                [nameInput]: value,
            }
            onModifyData(newData)
        }
        
    };

    return (
        <div className='flex justify-start items-end mb-3'>
            <span className=' mb-2'>
                <CheckBox
                    className={`text-lg font-bold text-gray-500 font-Formulario tracking-widest ${(label==='PRIMARIO' || label==='PRECORTE') ? 'pr-7':''}`}
                    checked={isChecked}
                    onChange={handleChange}
                    name={nameCheck}
                >
                    {label}
                </CheckBox>
            </span>
            <InputFormPanel
                className={!isChecked ? 'w-[100%]' : 'w-[100%] '}
                second={second}
                name={nameInput}
                value={textInput}
                onChange={handleChange}
                required={isChecked}
                disabled={!isChecked}
            >
                {second ? 'PROYECTO' : ''}
            </InputFormPanel>
        </div>
    );
}
