import { useAuth } from "../context/authContext";
import PanelGrafico from '../components/PanelFigura/PanelGrafico';
import FormsPanel from '../components/FormsPanel';
import Header from '../layouts/Header';
import { ref, set } from 'firebase/database';
import { db } from '../firebase';
import { useFetchData } from '../components/hooks/useFetchData';
import { updateData } from '../components/helpers/updateData';
import BackgroundStyle from "../components/ui/BackgroundStylePage";
import { useState } from "react";
import Alert from "../components/ui/Alert";
import Modal from "../components/ui/Modal";




const Home = () => {
    const { data, isLoading } = useFetchData()
    const { logout, loading } = useAuth()
    const [stateModal, setStateModal] = useState(false)
    const [stateModalLoading, setStateModalLoading] = useState(false)
    const [alert, setAlert] = useState('')
    const [dataForm, setDataForm] = useState({})

    const handleLogout = async () => {
        await logout()
    }

    const onModifyDatos = (dataForm) => {
        setStateModal(true)
        setDataForm(dataForm)
    }

    // const handleButtonClick = () => {
    //     const dataUpdate = updateData(dataForm)
    //     update(ref(db), dataUpdate)
    //     .then(() => {
    //         setStateModal(false)
    //         setAlert('Success')
    //     })
    //     .catch((errors) => {
    //         console.error(errors)
    //         setAlert('Error')
    //     })
    // }

    const handleButtonClick = async () => {
        try {
            setStateModalLoading(true)
            const dataUpdate = updateData(dataForm)
            for (const { key, value } of dataUpdate) {
                await set(ref(db, key), value)
            }
            setStateModal(false)
            setStateModalLoading(false)
            setAlert('Success')
        } catch (errors) {
            console.error(errors)
            setStateModal(false)
            setStateModalLoading(false)
            setAlert('Error')
        }
    }

    if(loading){
        return (<>  
            <BackgroundStyle></BackgroundStyle>
        </>)
    }

    return (
        <>
            {!isLoading && !loading ? (
                <>
                    {alert !== '' && <Alert stateAlert={alert} closeAlert={()=>{setAlert('')}}/>}
                    {stateModal && <Modal 
                                        clickBtn={handleButtonClick} 
                                        closeModal={ () => {setStateModal(false)}} 
                                        state={stateModalLoading}  
                                    />}
                    <div className={`w-full h-screen flex flex-col gap-8 items-center bg-[#dddddd] pt-[4rem] pb-[0rem] ${stateModal?'overflow-hidden':''}`}>
                        <Header salir={handleLogout}/>
                        <div className='flex w-[90%] h-[40rem] justify-around items-center max-[900px]:flex-col max-[900px]:h-fit '>
                            <PanelGrafico className="w-[50%] max-[900px]:w-[100%] mb-5" datos={data}/>
                            <FormsPanel className="w-[40%] max-[900px]:w-[60%] max-[700px]:w-[80%] max-[450px]:w-[100%] mb-5" datos={data} onModifyDatos={onModifyDatos}/>
                        </div>
                    </div>
                </>
            ) : (
                <div className="w-full h-screen flex flex-col gap-8 items-center bg-[#dddddd] pt-[4rem] pb-[0rem]">
                    <span className="w-full absolute overflow-hidden h-full bg-[#000000b8] z-[20] top-0"></span>
                    <Header salir={handleLogout}/>
                </div>
            )}
        </>
    );
    
}

export default Home