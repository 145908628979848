import React, { useState } from "react";
import LogoSvg from '../static/img/LogoIdeiperu.svg';
import {LabelStatus, InputForm, CheckBox, ButtonForm} from '../components/ui'
import { useAuth } from '../context/authContext'
import { useNavigate } from 'react-router-dom'

const Login = () => {
    const [isChecked, setIsChecked] = useState(true); // Estado inicial del checkbox
    const [user, setUser] = useState({
        Email: '',
        Password: '',
    })
    const [error, setError] = useState('Ingrese a su cuenta')
    const [stateText, setStateText] = useState('normal')

    const {login} = useAuth()
    const navigate = useNavigate()

    const handleChange = ({target: {name, value}}) => {
        setUser({...user, [name]: value})
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        try{
            await login(user.Email,user.Password)
            setStateText('success')
            navigate('/Home')
        }catch (errors){
            if (errors.code === 'auth/invalid-credential') {
                setError('Usuario o contraseña incorrectos')
                setStateText('error')
            }else{
                setError(errors.code)
                setStateText('error')
            }
        }
    }

    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };



    return (
        <form className="bg-white flex flex-col text-center px-[3.6458%] py-[2.8%] rounded-2xl w-[90%] h-auto absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%] gap-8 sm:w-[80%] sm:px-8 sm:py-10 md:w-[60%] lg:w-[33%] xl:px-[3.6458%] 2xl:w-[27.34375%] max-[450px]:py-12 max-[650px]:py-12" onSubmit={handleSubmit}>
            <img className="w-[82.78%] m-auto" src={LogoSvg} alt="LogoIdeiperu"/>
            <LabelStatus state={stateText} >{error && <p>{error}</p>}</LabelStatus>
            <div className="flex flex-col text-center w-full  gap-[15px] ">
                <InputForm 
                    id="Email"
                    name="Email"
                    type="email"
                    placeholder=""
                    onChange={handleChange}
                    >Ingrese su correo</InputForm>
                <InputForm 
                    id="Password"
                    name="Password"
                    type="password"
                    placeholder=""
                    onChange={handleChange}
                    >Ingrese su contraseña</InputForm>
                <div className="w-full flex py-[5px] justify-between items-center">
                    <CheckBox 
                        className="text-[#6B6B6B] font-Poppins text-xs w-full font-bold leading-5" 
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                    >Permanecer Conectado</CheckBox>
                    <span className="text-[#B80D01] font-Poppins text-xs font-bold leading-5 underline underline-offset-5 cursor-pointer">Ha olvidado la contraseña</span>
                </div>
            </div>
            <div className="flex items-center justify-around">
                <ButtonForm tipo="Link" to="/Register" >Registrar</ButtonForm>
                <ButtonForm tipo="Form" >Ingresar</ButtonForm>

            </div>
        </form>
    );
}

export default Login