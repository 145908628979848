import { TfiClose } from 'react-icons/tfi';
import { PiWarningCircleDuotone } from 'react-icons/pi';
import RingLoader  from "react-spinners/RingLoader";

export default function Modal({ clickBtn, closeModal, state=false }) {
    return (
        <>
            <div className={`w-full h-full absolute overflow-hidden bg-[#000000dd] top-0 flex justify-center items-center z-20`}>
                <div className='w-[30%] h-auto bg-white flex flex-col justify-center items-center rounded-2xl shadow-[0px_0px_20px_-6px_#a00] max-[1200px]:w-[40%] max-[1000px]:w-[50%] max-[800px]:w-[70%] max-[550px]:w-[90%]'>
                    <span className='header w-full px-5 pt-5 font-Poppins uppercase text-2xl flex justify-center items-center relative'>
                        <PiWarningCircleDuotone size='4rem'/>
                        <TfiClose size='1.2rem' className='absolute right-5 top-5 cursor-pointer' onClick={closeModal}/>
                    </span>
                    <span className='body w-full max-h-[30rem] px-5 py-3 font-Poppins text-[1.2rem] font-normal overflow-y-auto text-center flex justify-center items-center relative'>
                        { state ? <RingLoader color="#36d7b7" /> : '¿Estás seguro que desea modificar el panel informativo?' }
                        
                    </span>
                    <span className='footer w-full px-5 pb-5 pt-2 h-fit flex justify-center items-center relative '>
                        <button 
                            className={`flex justify-center items-center w-fit right-2 px-4 py-2 rounded-lg bg-white text-[#B80D01] font-Poppins font-medium text-[1.1rem] drop-shadow-lg shadow-[2px_2px_4px_0px_#a00] hover:bg-[#B80D01] hover:text-white focus:bg-[#B80D01] focus:text-white transition-all ease-in-out delay-100 ${state && 'animate-aniBtn !bg-[#B80D01] !text-white'}`}
                            disabled={state}
                            onClick={clickBtn}
                            
                        >
                            { state ? 'Subiendo información' : 'Sí, estoy seguro' }
                        </button>
                    </span>
                    
                </div>
            </div>
        </>
    )
}
