export default function Carousel({ children, posicion }) {
    const childrenCount = Array.isArray(children) ? children.length : 1
    const childs = []

    for (let i = 0; i < childrenCount; i++) {
        childs.push(
            <div key={i} className="min-w-full h-full">
                {children[i]}
            </div>
        )
    }

    return (
        <div className='overflow-hidden w-full h-auto flex justify-center items-center'>
            <div className='flex transition-transform ease-out duration-500 w-full ' style={{ transform: `translateX(-${posicion * 100}%)` }}>
                {childs}
            </div>
        </div>
    );
}