
import { useEffect, useState } from 'react'
import { onValue, ref } from 'firebase/database'
import { db } from '../../firebase'
import { formatWebData } from '../helpers/formatWebData'

export const useFetchData = () => {
    const [state, setState] = useState({
        data: formatWebData(),
        isLoading: true,
        errors: null
    })

    const getFetch = async () => {
        try{
            const listnerRef = ref(db, 'PV_Southern/Blasting_Panel/Address/0/Fields')
            onValue(listnerRef, (snapshot) => {
                if(snapshot.exists()){
                    const datos = formatWebData(snapshot.val())
                    setState({
                        data: datos,
                        isLoading: false,
                        errors: null
                    })
                } else{
                    console.error("no existe database")
                    setState({
                        data: null,
                        isLoading: false,
                        errors: null
                    })
                }
        
            })
    
        } catch(error) {
            console.error(error)
            setState({
                data: null,
                isLoading: false,
                errors: null
            })
        }
    }


    useEffect(() => {
        getFetch()
    }, [])

    return state
}
