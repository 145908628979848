const FIELD_SEPARATOR = '~';
const DATE_SEPARATOR = '-';

const getDefaultFormattedData = () => ({
    titulo: '',
    fecha: '',
    desde: '',
    hasta: '',
    disparo1Pala: '',
    disparo2Pala: '',
    disparo3Pala: '',
    disparo1Proyecto1: '',
    disparo1Proyecto2: '',
    disparo1Proyecto3: '',
    disparo2Proyecto1: '',
    disparo2Proyecto2: '',
    disparo2Proyecto3: '',
    disparo3Proyecto1: '',
    disparo3Proyecto2: '',
    disparo3Proyecto3: '',
    disparo1Tipo1: false,
    disparo1Tipo2: false,
    disparo1Tipo3: false,
    disparo2Tipo1: false,
    disparo2Tipo2: false,
    disparo2Tipo3: false,
    disparo3Tipo1: false,
    disparo3Tipo2: false,
    disparo3Tipo3: false,
});

const getResults = (dataDB) => {
    const results = {};
    dataDB.forEach(data => {
        results[data.ID] = data.Text;
    });
    return results;
}

const getFormattedData = (results) => ({
    titulo: results.campo11.trim(),
    fecha: results.campo10.trim(),
    desde: results.campo9?.split(DATE_SEPARATOR)[0].trim(),
    hasta: results.campo9?.split(DATE_SEPARATOR)[1].trim(),
    disparo1Pala: results.campo2.trim(),
    disparo2Pala: results.campo5.trim(),
    disparo3Pala: results.campo8.trim(),
    disparo1Proyecto1: results.campo1?.split(FIELD_SEPARATOR)[1].trim(),
    disparo1Proyecto2: results.campo1?.split(FIELD_SEPARATOR)[2].trim(),
    disparo1Proyecto3: results.campo1?.split(FIELD_SEPARATOR)[3].trim(),
    disparo2Proyecto1: results.campo4?.split(FIELD_SEPARATOR)[1].trim(),
    disparo2Proyecto2: results.campo4?.split(FIELD_SEPARATOR)[2].trim(),
    disparo2Proyecto3: results.campo4?.split(FIELD_SEPARATOR)[3].trim(),
    disparo3Proyecto1: results.campo7?.split(FIELD_SEPARATOR)[1].trim(),
    disparo3Proyecto2: results.campo7?.split(FIELD_SEPARATOR)[2].trim(),
    disparo3Proyecto3: results.campo7?.split(FIELD_SEPARATOR)[3].trim(),
    disparo1Tipo1: results.campo0?.split(FIELD_SEPARATOR)[1] === 'PRIMARIO' ? true : false,
    disparo1Tipo2: results.campo0?.split(FIELD_SEPARATOR)[2] === 'PRECORTE' ? true : false,
    disparo1Tipo3: results.campo0?.split(FIELD_SEPARATOR)[3] === 'SECUNDARIO' ? true : false,
    disparo2Tipo1: results.campo3?.split(FIELD_SEPARATOR)[1] === 'PRIMARIO' ? true : false,
    disparo2Tipo2: results.campo3?.split(FIELD_SEPARATOR)[2] === 'PRECORTE' ? true : false,
    disparo2Tipo3: results.campo3?.split(FIELD_SEPARATOR)[3] === 'SECUNDARIO' ? true : false,
    disparo3Tipo1: results.campo6?.split(FIELD_SEPARATOR)[1] === 'PRIMARIO' ? true : false,
    disparo3Tipo2: results.campo6?.split(FIELD_SEPARATOR)[2] === 'PRECORTE' ? true : false,
    disparo3Tipo3: results.campo6?.split(FIELD_SEPARATOR)[3] === 'SECUNDARIO' ? true : false,
});


export const formatWebData = (dataDB = {}) => {
    try {
        if (Object.entries(dataDB).length === 0) {
            return getDefaultFormattedData();
        } else {
            const results = getResults(dataDB);
            return getFormattedData(results);
        }
    } catch (error) {
        console.error('Error en formatData:', error);
        return getDefaultFormattedData();
    }
}