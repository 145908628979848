import { BrowserRouter, Routes, Route } from 'react-router-dom' 
import { AuthProvider } from './context/authContext'

import Home from './pages/Home'
import Login from './pages/Login'
import Register from './pages/Register'
import BackgroundStyle from './components/ui/BackgroundStylePage'
import { ProtectedRoute } from './services/ProtectedRoute'
import { RedirectPages } from './services/RedirectPages'


export default function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route path='/' element={
            <RedirectPages>
              <BackgroundStyle>
                <Login />
              </BackgroundStyle>
            </RedirectPages>
          } />
          <Route path='/Register' element={
            <RedirectPages>
              <BackgroundStyle>
                <Register />
              </BackgroundStyle>
            </RedirectPages>
          } />
          <Route path='/Home' element={
            <ProtectedRoute>
              <Home />
            </ProtectedRoute>
          } />
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  )
}