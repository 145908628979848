import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyDJiYG1s5Szjk3eoRhEXjIsYk6eajNKuEQ",
  authDomain: "ideitech-blastpanel.firebaseapp.com",
  databaseURL: "https://ideitech-blastpanel-default-rtdb.firebaseio.com/",
  projectId: "ideitech-blastpanel",
  storageBucket: "ideitech-blastpanel.appspot.com",
  messagingSenderId: "324843977234",
  appId: "1:324843977234:web:56ab36e98fc9e7b527b6cb",
  measurementId: "G-87M2E4T2GG"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app)
const db = getDatabase(app)

export {app, auth, db}