import { useState } from "react";

export default function ButtonGroup({ children, handleButtonClick }) {
    const [activeIndex, setActiveIndex] = useState(0);
    const handleChildClick = (index) => {
        setActiveIndex(index);
        handleButtonClick(index);
    };

    const childrenCount = Array.isArray(children) ? children.length : 1
    const childs = []

    for (let i = 0; i < childrenCount; i++) {
        const isActive = activeIndex === i;
        const clase = isActive
            ? "inline-block rounded-md bg-white px-4 py-2 text-md hover:text-[#B80D01] flex-1 font-Poppins shadow-[1px_2px_8px_-2px_rgba(184,13,1,1)] text-[#B80D01]"
            : "inline-block rounded-md px-4 py-2 text-md text-gray-500 focus:text-[#B80D01] hover:text-[#B80D01] flex-1 font-Poppins";

        childs.push(
            <button
                key={i}
                className={clase}
                type="button"
                onClick={() => handleChildClick(i)}
            >
                {children[i]}
            </button>
        );
    }


    return (
        <div
            className="inline-flex rounded-lg border border-gray-100 bg-gray-100 p-1  w-full shadow-md text-[2rem] font-Poppins  text-black font-medium text-sm   "
            >
            {childs}
        </div>
    )
}
